// 管理员
import $axios from '@/config/axios';
import {bizURL} from '@/config/baseUrl';

function getToken() {
    var getTokenId = sessionStorage.getItem("manageToken");
    if (!getTokenId) {
        getTokenId = '';
    }
    return getTokenId;
}

const portData = {
    biz_gender(query) {  // 性别
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_gender`,
            method: "get",
            params: query
        });
    },
    biz_degree(query) {  // 学历
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_degree`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_exam_speciality(query) {  // 测试专业名称
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_exam_speciality`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_exam_speciality_level(query) {  // 测试专业级别
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_exam_speciality_level`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_job(query) {  // 测试专业职务
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_job`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_title(query) {  // 测试专业职称
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_title`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    manageLogin(query) {  // 管理员登录
        return $axios({
            url: `${bizURL}/loginForM`,
            method: "post",
            data: query
        });
    },
    logout(query) {  // 退出登录
        return $axios({
            url: `${bizURL}/logout`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    updatePwd(query) {  // 修改密码
        return $axios({
            url: `${bizURL}/system/user/profile/updatePwd`,
            method: "put",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    getInfo(query) {  // 获取个人信息
        return $axios({
            url: `${bizURL}/getInfo`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    auditList(query) {  // 审核报名列表
        return $axios({
            url: `${bizURL}/registration/check/list`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    download(fileName) {  // 下载导出文件
        var ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
        var downLoad = true;
        if (ext == '.pdf') {
            downLoad = false;
        }
        var url = `${bizURL}/common/download?fileName=${fileName}&delete=true&downLoad=${downLoad}`;
        window.open(url);
    },
    export(query) {  // 导出报名列表
        return $axios({
            url: `${bizURL}/registration/check/export`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    getRegistrationInfo(query) {  // 获取单条报名记录
        return $axios({
            url: `${bizURL}/registration/registration/getById`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    commentList(query) {  // 查看审核意见
        return $axios({
            url: `${bizURL}/registration/check/commentList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    check(query) {  // 报名审核
        return $axios({
            url: `${bizURL}/registration/check`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    auditSubmit(query) {  // 提交审核(审核撤回)
        return $axios({
            url: `${bizURL}/registration/registration/submit`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    getRegistrationFeeList(query) {  // 报名缴费列表
        return $axios({
            url: `${bizURL}/registration/payRecord/getRegistrationFeeList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    getExamResultsList(query) {  // 成绩录入列表
        return $axios({
            url: `${bizURL}/registration/examResult/getExamResultsList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    getExamRegistrationList(query) {  // 计划考生列表
        return $axios({
            url: `${bizURL}/registration/examResult/getExamRegistrationList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    updateExamRegistration(query) {  // 成绩录入(修改成绩)
        return $axios({
            url: `${bizURL}/registration/examResult/updateExamRegistration`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    getExamAchievementList(query) {  // 考试成绩
        return $axios({
            url: `${bizURL}/registration/examResult/getExamAchievementList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    examPlan(query) {  // 制定考试计划列表
        return $axios({
            url: `${bizURL}/registration/examPlan/list`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    // 导出考试计划
    exportExamPlanList(query) {
        return $axios({
            url: `${bizURL}/registration/examPlan/export`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    updateSeat(query) {  // 修改座位号(保存)
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration`,
            method: "put",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    biz_examSubject(query) {  // 获取考试科目
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_examSubject`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    noExamPlanCount(query) {  // 获取待安排考试计划的人数
        return $axios({
            url: `${bizURL}/registration/examPlan/noExamPlanCount`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    noExamPlanlist(query) {  // 获取待安排考试的报名列表
        return $axios({
            url: `${bizURL}/registration/examPlan/noExamPlanlist`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    publish(query) {  // 发布考试计划
        return $axios({
            url: `${bizURL}/registration/examPlan/submit`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    submitAll(query) {  // 发布考试计划(全部)
        return $axios({
            url: `${bizURL}/registration/examPlan/submitAll`,
            method: "post",
            headers: {'Authorization': getToken()},
            timeout: 3 * 60 * 1000,
            data: query
        });
    },
    cardSubmit(query) {  // 生成准考证
        return $axios({
            url: `${bizURL}/registration/examPlan/genExamTicketNo`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    genExamTicketNoAll(query) {  // 生成准考证(全部)
        return $axios({
            url: `${bizURL}/registration/examPlan/genExamTicketNoAll`,
            method: "post",
            headers: {'Authorization': getToken()},
            timeout: 3 * 60 * 1000,
            data: query
        });
    },
    replace(query) {  // 交换考生
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration/replace`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    deleteById(query) {  // 删除
        return $axios({
            url: `${bizURL}/registration/examPlan/deleteById`,
            method: "delete",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    getExamById(id) {  // 获取单条考试计划(修改)
        return $axios({
            url: `${bizURL}/registration/examPlan/getById`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: {id: id}
        });
    },
    saveExamPlan(query) {  // 保存考试计划
        return $axios({
            url: `${bizURL}/registration/examPlan`,
            method: "put",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    examPlanRegistrationList(query) {  // 获取考试计划人员明细
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration/examPlanRegistrationList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    autoMakeExamPlan(query) {  // 自动分配考生(保存)
        return $axios({
            url: `${bizURL}/registration/examPlan/autoMakeExamPlan`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    addExamPlan(query) {  // 新增考试计划
        return $axios({
            url: `${bizURL}/registration/examPlan`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    relExamplanRegistration(query) {  // 新增考生
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    deleteRelExamById(id) {  // 刪除考生(已分配)
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration/deleteById`,
            method: "delete",
            headers: {'Authorization': getToken()},
            params: {id: id}
        });
    },
    selectExamResultSummary(query) {  // 获取成绩汇总
        return $axios({
            url: `${bizURL}/registration/passline/examResultSummary`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    savePassLine(query) {  // 保存合格条件
        return $axios({
            url: `${bizURL}/registration/passline/save`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    confirmPassScoreLine(query) {  // 确认合格条件
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration/confirmPassScoreLine`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    genCertificate(query) {  // 生成证书
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration/genCertificate`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    publishResult(query) {  // 发布
        return $axios({
            url: `${bizURL}/registration/relExamplanRegistration/publishResult`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    workFlowlist(query) {  // 审核记录
        return $axios({
            url: `${bizURL}/registration/registration/workFlowlist`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    payList(query) {  // 设置缴费金额(获取list)
        return $axios({
            url: `${bizURL}/registration/payconfig/list`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    updatePay(query) {  // 设置缴费金额(修改)
        return $axios({
            url: `${bizURL}/registration/payconfig`,
            method: "put",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    exportRegistrationFeeList(query) {  // 导出缴费记录
        return $axios({
            url: `${bizURL}/registration/payRecord/exportRegistrationFeeList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    exportExamAchievementList(query) {  // 导出成绩查询
        return $axios({
            url: `${bizURL}/registration/examResult/exportExamAchievementList`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    exportRegistration(query) {  // 导出成绩录入
        return $axios({
            url: `${bizURL}/registration/examPlan/exportRegistration`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    importAchievement(query) {  // 批量导入
        return $axios({
            url: `${bizURL}/registration/examResult/importAchievement`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    userList(query) {  // 用户管理(列表)
        return $axios({
            url: `${bizURL}/registration/user/list`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    addUser(query) {  // 用户管理(新增)
        return $axios({
            url: `${bizURL}/registration/user`,
            method: "post",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    updateUser(query) {  // 用户管理(修改)
        return $axios({
            url: `${bizURL}/registration/user`,
            method: "put",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    deleteUserById(query) {  // 用户管理(删除)
        return $axios({
            url: `${bizURL}/registration/user/deleteUserById`,
            method: "delete",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    resetPwd(query) {  // 用户管理(重置密码)
        return $axios({
            url: `${bizURL}/registration/user/resetPwd`,
            method: "put",
            headers: {'Authorization': getToken()},
            data: query
        });
    },
    getBatchOptions()
    {  //获取批次
        return $axios({
            url: `${bizURL}/registration/registration/getBatchOptions`,
            method: "get",
            headers: {'Authorization': getToken()},
        });
    },

}

export default portData;



