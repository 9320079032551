import axios from 'axios';
import router from '@/router/index';

// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

let config = {
    //     baseURL: process.env.baseURL || process.env.apiUrl || ""
    timeout: 30 * 1000, // Timeout
    //   withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config);

_axios.baseURL = process.env.VUE_APP_URL;

// console.log(process.env.VUE_APP_URL, 'VUE_APP_URL');

// 添加请求拦截器
/* _axios.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        if (sessionStorage.getItem('token')) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            // config.headers.Authorization = 'Bearer ' + sessionStorage.getItem("token");
            config.headers.Authorization = sessionStorage.getItem("token");
        }
        return config;
    },
    err => {
        // 对请求错误做些什么
        return Promise.reject(err);
    }
); */
_axios.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
/* _axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log("response error :" + error);
        if (error.response) {
            console.log(router.currentRoute.fullPath, 'router.currentRoute.fullPath')
            switch (error.response.status) {
                case 401:
                    console.log("token 过期");
                    router.replace({
                        path: '/login',
                        query: { redirect: router.currentRoute.fullPath }
                    })
            }
        }
        return Promise.reject(error.response.data)  // 返回接口返回的错误信息
    }
); */
_axios.interceptors.response.use(
    function (response) {
        if (response.data.code == '401') {
            var current = router.history.current.path;
            if (current !== '/login') {
                router.replace('/login');
            }
            return false;
            // throw new Error("loginTimeout");
        }
        // 对响应数据做点什么
        return response.data;   
    },
    function (error) {

        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default _axios;

