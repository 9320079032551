import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/config/student';
import comHttp from '@/config/common';
import manageHttp from '@/config/manage';
//引入图片查看器
// import vueViewer from 'vue2-viewer';
// Vue.use(vueViewer);
// 竖向表格
// import moreTreeTable from "more-tree-table";
// Vue.use(moreTreeTable);

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": true,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});


Vue.config.productionTip = false

import {
    Button,
    Row,
    Col,
    Icon,
    Message,
    Tabs,
    TabPane,
    Dropdown,
    DropdownMenu,
    Input,
    InputNumber,
    Form,
    FormItem,
    Menu,
    MenuItem,
    Submenu,
    MenuGroup,
    Avatar,
    Modal,
    Upload,
    RadioGroup,
    Radio,
    Divider,
    Select,
    Option,
    Table,
    Page,
    DatePicker,
    Progress,
    Step,
    Steps,
    Tag,
    Spin
} from 'view-design';

Vue.component('Button', Button);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Icon', Icon);
Vue.component('Message', Message);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('Input', Input);
Vue.component('InputNumber', InputNumber);
Vue.component('Form', Form);
Vue.component('FormItem', FormItem);
Vue.component('Menu', Menu);
Vue.component('MenuItem', MenuItem);
Vue.component('Submenu', Submenu);
Vue.component('MenuGroup', MenuGroup);
Vue.component('Avatar', Avatar);
Vue.component('Modal', Modal);
Vue.component('Upload', Upload);
Vue.component('RadioGroup', RadioGroup);
Vue.component('Radio', Radio);
Vue.component('Divider', Divider);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('DatePicker', DatePicker);
Vue.component('Progress', Progress);
Vue.component('Step', Step);
Vue.component('Steps', Steps);
Vue.component('Tag', Tag);
Vue.component('Spin', Spin);

import 'view-design/dist/styles/iview.css';

Vue.prototype.$Message = Message;
Vue.prototype.$Modal = Modal;
Vue.prototype.$http = http; // 用户
Vue.prototype.$comHttp = comHttp;
Vue.prototype.$manageHttp = manageHttp; // 管理部门


router.beforeEach((to, from, next) => {
    //自动登录
    let token = to.query.token;
    if (token) {
        sessionStorage.setItem("token", token);
    }
    if (auth(to)) {
        next();
    } else {
        next({path:"/login"});
    }
})
var auth = function (to) {
    var flag = false;
    var roles = to.meta && to.meta.roles ? to.meta.roles : [];
    if (roles.length == 0) {
        flag = true;
    } else {
        if (roles.indexOf("student") > -1) {
            var token = sessionStorage.getItem("token")
            if (token) {
                flag = true;

            }
        } else if (roles.indexOf("admin") > -1) {

            var manageToken = sessionStorage.getItem("manageToken")
            if (manageToken) {
                flag = true;
            }
        }

    }
    return flag;

}
// console.log(process.env.VUE_APP_URL,'main.js');
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
