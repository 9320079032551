import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/student/home/home.vue'), // 报名中心
    meta: {
      roles: ['student']
    },
  },
  {
    path: '/tab',
    name: 'tab',
    component: () => import('../views/student/home/tab.vue') // 测试页面
  },
  {
    path: '/applyPay',
    name: 'applyPay',
    component: () => import('../views/student/applyPay/applyPay.vue'), // 报名缴费
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('../views/student/print/print.vue') // 打印准考证
  },
  {
    path: '/performance',
    name: 'performance',
    component: () => import('../views/student/performance/performance.vue'), // 成绩查询
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/student/personal/personal.vue') // 个人信息
  },
  // 管理部门
  {
    path: '/audit',
    name: 'audit',
    component: () => import('../views/manage/audit/audit.vue'), // 审核报名
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/adminRecord',
    name: 'adminRecord',
    component: () => import('../views/manage/adminRecord/adminRecord.vue'), // 审核记录
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/adminPay',
    name: 'adminPay',
    component: () => import('../views/manage/adminPay/adminPay.vue') ,// 报名缴费
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/adminPlan',
    name: 'adminPlan',
    component: () => import('../views/manage/adminPlan/adminPlan.vue'), // 制定考试计划
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/adminPerformance',
    name: 'adminPerformance',
    component: () => import('../views/manage/adminPerformance/adminPerformance.vue'), // 成绩管理
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/adminEnter',
    name: 'adminEnter',
    component: () => import('../views/manage/adminEnter/adminEnter.vue'), // 成绩管理
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/adminAccount',
    name: 'adminAccount',
    component: () => import('../views/manage/adminAccount/adminAccount.vue'), // 账户管理
    meta: {
      roles: ['admin']
    },
  },
  {
    path: '/apply',
    name: 'apply',
    // component: () => import('../views/student/applyPay/apply.vue') // 支付
    component: () => import('../views/student/home/apply.vue'), // 支付
    meta: {
      roles: ['student']
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
