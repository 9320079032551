import $axios from '@/config/axios';
import {bizURL} from '@/config/baseUrl';

function getToken() {
    var getTokenId = sessionStorage.getItem("token");
    return getTokenId;
}

const portData = {


    getCodeImg() {
        return $axios({
            url: `${bizURL}/captchaImage`,
            method: "get"
        });
    },
    biz_gender(query) {  // 性别
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_gender`,
            method: "get",
            params: query
        });
    },
    biz_degree(query) {  // 学历
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_degree`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_exam_speciality(query) {  // 测试专业名称
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_exam_speciality`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_exam_speciality_level(query) {  // 测试专业级别
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_exam_speciality_level`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_job(query) {  // 测试专业职务
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_job`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
    biz_title(query) {  // 测试专业职称
        return $axios({
            url: `${bizURL}/system/dict/data/type/biz_title`,
            method: "get",
            headers: {'Authorization': getToken()},
            params: query
        });
    },
}

export default portData;