// 用户端
import $axios from '@/config/axios';
import { bizURL } from '@/config/baseUrl';

function getToken() {
  var getTokenId = sessionStorage.getItem("token");
  return getTokenId;
}

const portData = {
  login(query) {  // 学员登录
    return $axios({
      url: `${bizURL}/loginForXY`,
      method: "post",
      data: query
    });
  },
  logout(query) {  // 退出登录
    return $axios({
      url: `${bizURL}/logout`,
      method: "post",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  registration(query) {  // 个人注册
    return $axios({
      url: `${bizURL}/registration/employee/add`,
      method: "post",
      data: query
    });
  },
  getUserInfo(query) {  // 获取个人信息
    return $axios({
      url: `${bizURL}/registration/employee/my`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  async save(query) {  // 保存报名表
    return await $axios({
      url: `${bizURL}/registration/registration`,
      method: "post",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  getMyLast(query) {  // 获取我的报名信息
    return $axios({
      url: `${bizURL}/registration/registration/getMyLast`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  applyList(query) {  // 获取报名列表
    return $axios({
      url: `${bizURL}/registration/registration/list`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  addApply(query) {  // 新增报名
    return $axios({
      url: `${bizURL}/registration/registration`,
      method: "post",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  getLastByRegId(query) {  // 获取缴费列表
    return $axios({
      url: `${bizURL}/registration/payRecord/getLastByRegId`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  download(fileName) {  // 下载导出文件
    var ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    // console.log(ext,'ext');
    var downLoad = true;
    if (ext == '.pdf') {
      downLoad = false;
    }
    var url = `${bizURL}/common/download?fileName=${fileName}&delete=true&downLoad=${downLoad}`;
    window.open(url);
    // var tempwindow = window.open('_blank'); // 先打开页面
    // tempwindow.location = url; // 后更改页面地址
  },
  deleteApply(query) {  // 删除报名
    return $axios({
      url: `${bizURL}/registration/registration`,
      method: "delete",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  getRegistrationInfo(query) {  // 获取单条报名记录
    return $axios({
      url: `${bizURL}/registration/registration/getById`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  updateApply(query) {  // 修改报名信息
    return $axios({
      url: `${bizURL}/registration/registration`,
      method: "put",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  auditInfo(query) {  // 提交审核
    return $axios({
      url: `${bizURL}/registration/registration/submit`,
      method: "post",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  commentList(query) {  // 审核记录
    return $axios({
      url: `${bizURL}/registration/check/commentList`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  print(query) {  // 打印报名表
    return $axios({
      url: `${bizURL}/registration/registration/print`,
      method: "post",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  payRecord(query) {  // 报名缴费列表
    return $axios({
      url: `${bizURL}/registration/payRecord/getUserRegistrationFeeList`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: query
    });
  },
  updateRegistrationStatus(query) {  // 缴费
    return $axios({
      url: `${bizURL}/registration/payRecord/updateRegistrationStatus`,
      method: "post",
      headers: { 'Authorization': getToken() },
      data: query
    });
  },
  examTicket(id) {  // 获取准考证信息
    return $axios({
      url: `${bizURL}/registration/examPlan/examTicket`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: { regId: id }
    });
  },
  printExamTicket(id) {  // 打印准考证
    return $axios({
      url: `${bizURL}/registration/examPlan/printExamTicket`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: { regId: id }
    });
  },
  getResult(id) {  // 获取证书信息
    return $axios({
      url: `${bizURL}/registration/relExamplanRegistration/getResult`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: { regId: id }
    });
  },
  printCertificate(id) {  // 打印证书
    return $axios({
      url: `${bizURL}/registration/certificate/printCertificate`,
      method: "get",
      headers: { 'Authorization': getToken() },
      params: { regId: id }
    });
  },
}

export default portData;