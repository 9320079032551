/* 本地测试地址 */
//var baseUrl = 'http://192.168.1.147:8090' // 本地
var baseUrl = process.env.VUE_APP_URL
console.log(baseUrl,'sssss')
// var baseUrl = 'http://192.168.1.6:8090' // 本地
// var baseUrl = 'http://169.254.1.100:8090' // 本地

// var baseUrl = 'shuice.eceyun.net:8000' // 线上 
// var baseUrl = 'http://101.133.216.156:8090' // 线上
// var baseUrl = 'http://119.36.213.157:8090' // 线上

export const bizURL = baseUrl;
// export const cosURL = cosUrl;

